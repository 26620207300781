import * as React from "react";
import NumberedList from "../../../shared/ui/numberedList/numberedList";
import styles from "./research.module.scss";

export function Research() {
  return (
    <div>
      <h2 className={styles.Title}>ИССЛЕДОВАНИЯ 2021-2022</h2>

      <NumberedList
        data={[
          <>
            Обучение учеников 1-4 класса по методике
            <a
              className={styles.Link}
              href="https://mel.fm/blog/nikolay-arkharov/97315-sistema-elkonina-davydova-i-fgos-2020-o-budushchem-shkoly"
            >
              {" "}
              Эльконина-Давыдова.
            </a>
          </>,
          <>
            Автоматический разбор письменных домашних заданий. Выполняется
            совместно c{" "}
            <a className={styles.Link} href="https://nemosoft.ru/">
              ООО НеМо{" "}
            </a>{" "}
            ИНН 7810196160
          </>,

          <>
            Обучение математике на английском и немецком языках для
            русскоговорящих учеников.
          </>,
        ]}
      />
    </div>
  );
}
