import { $host } from "./index";

const api =
  window.location.host === "test.minimath239.ru"
    ? process.env.REACT_APP_API_TEST_URL
    : process.env.REACT_APP_API_URL;
const schoolEndpoint = "school";

export default class SchoolService {
  static async getSchoolList() {
    return await $host.get(api + schoolEndpoint);
  }

  static async getSchoolById(id: string) {
    return await $host.get(api + schoolEndpoint + id);
  }
}
