import React from "react";
import { Link } from "react-router-dom";
import MinimathEmail from "../../../components/minimathSocials/MinimathEmail";
import TelegramFefelova from "../../../components/minimathSocials/TelegramFefelova";
import List from "../../../shared/ui/list/list";
import styles from "./lessonCost.module.scss";

export function LessonCost() {
  return (
    <div>
      <p className={styles.Text}>
        Онлайн обучение проходит с помощью Zoom и виртуальной доски Idroo
      </p>

      <p className={styles.Text}>Стоимость:</p>

      <List
        pointerCircle={true}
        data={[
          "1500 рублей за 50 минут для индивидуальных занятий,",
          "2000 рублей для группы из 2-х учеников,",
          "2400 рублей для 3-х учеников.",
        ]}
      />

      <p className={styles.Text}>
        О наших преподавателях:{" "}
        <strong>
          <Link className={styles.Link} to={"/feedback"}>
            {" "}
            Отзывы{" "}
          </Link>

        </strong>
        и
        <strong>

          <Link className={styles.Link} to={"/achievements"}>
            {" "}
            Результаты работ
          </Link>
          .

        </strong>
      </p>
      <p className={styles.Text}>
        Для начала обучения необходимо послать заявку на проведение первого
        занятия на:
      </p>

      <div>
        <MinimathEmail
          textClassName={styles.Link}
          imageClassName={styles.LinkImage}
        />{" "}
        и{" "}
        <TelegramFefelova
          textClassName={styles.Link}
          imageClassName={styles.LinkImage}
        />
      </div>
    </div>
  );
}
