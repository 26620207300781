import * as React from "react";
import Logo from "../../shared/ui/logo/logo";
import { TLinkProps } from "../../types/common/LinkPropsType";
import styles from "./Footer.module.scss";

const topFooterLinkArray: TLinkProps[] = [
  {
    href: "/feedback",
    label: "Отзывы",
    colSpan: 2,
    key: "2",
  },
  {
    href: "/parents",
    label: "Родителям",
    colSpan: 3,
    key: "3",
  },
  {
    href: "/achievements",
    label: "Достижения",
    colSpan: 3,
    key: "4",
  },
  {
    href: "/our-team",
    label: "Команда",
    colSpan: 2,
    key: "5",
  },
  {
    href: "/articles",
    label: "Статьи",
    colSpan: 2,
    key: "6",
  },
];

function FooterTop() {
  return (
    <div className={styles.LogoAndLinksContainer}>
      <Logo className={styles.Logo} />
      <div className={styles.Container}>
        {topFooterLinkArray.map((labelLink) => (
          <a
            className={styles.LinkText}
            href={labelLink.href}
            key={labelLink.label}
          >
            {labelLink.label}
          </a>
        ))}
      </div>
    </div>
  );
}

export default FooterTop;
