import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/button";
import CustomTable from "../../../shared/ui/container/customTable";
import { TCourseThemes } from "../../../types/CourseType";
import { fetchThemes } from "../api/fetchThemes";
import styles from "./olympiades.module.scss";

export function Olympiades() {
  const [themes, setThemes] = useState<TCourseThemes[]>([]);

  useEffect(() => {
    fetchThemes().then((response) => {
      setThemes([...response.data]);
    });
  }, []);

  return (
    <div className={styles.Wrapper}>
      <div>
        <h2 className={styles.Title}>ОЛИМПИАДНАЯ МАТЕМАТИКА</h2>

        <p className={styles.Text}>
          На 27 августа 2022 года в публичном доступе 2000 задач для учеников
          3-4 класса, сгруппированных по 17 темам.
        </p>

        <CustomTable tableData={themes.slice(0, 23)} />

        <Link to={"/theme"}>
          <Button>
            <span> Все темы</span>
          </Button>
        </Link>
      </div>
      <img
        width="295"
        height="253"
        className={styles.Image}
        src={"/images/movement2.svg"}
        alt=""
      />
    </div>
  );
}
