import { ReactNode } from "react";
import styles from "./numberedList.module.scss";

interface INumberedListProps {
  data: ReactNode[];
}

function NumberedList(props: INumberedListProps) {
  const { data } = props;
  let x = 1;

  return (
    <div>
      <ol className={styles.List}>
        {data.map((line) => (
          <li key={(x++).toString()}>
            <span className={styles.ListText}>{line}</span>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default NumberedList;
