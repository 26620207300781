import { $authHost } from "./index";
import { TEditTask } from "../types/EditTaskType";
import { TEditCourse } from "../types/EditCourseType";

const api = "admin/";

export default class AdminService {
  /**
   * Метод для получения заданий по курсу
   * @param id - айди курса
   * @return {Promise<any>}
   */
  static async getCourseById(id: number) {
    return await $authHost.get(api + "course/" + id);
  }

  /**
   * Получение задачи по айди
   * @param id - айди задачи
   */
  static async getTaskById(id: number) {
    return await $authHost.get(api + "task/" + id);
  }

  /**
   * Метод для обновления информации по задаче
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async putUpdatedTask(data: TEditTask) {
    // console.dir(data, {depth:null})
    return await $authHost.put(api + "task/" + data.id, data);
  }

  /**
   * Метод для обновления информации по курсу
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async putUpdatedCourse(data: TEditCourse) {
    return await $authHost.put(api + "course/" + data.id, data);
  }

  /**
   * Метод для создания задачи
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async createTask(data: TEditTask) {
    data.id = undefined;
    return await $authHost.post(api + "task", data);
  }

  /**
   * Метод для создания курса
   * @param data - обновленные данные
   * @return {Promise<any>}
   */
  static async createCourse(data: TEditCourse) {
    data.id = undefined;
    return await $authHost.post(api + "course", data);
  }

  /**
   * Метод для загрузки файла
   * @param data - обновленные данные
   * @param type - тип файла (course or task)
   * @param field - поле для обновления в БД (answerImage, questionImage, image)
   * @param id - айди строки для обновления
   * @return {Promise<any>}
   */
  static async uploadImage(
    data: any,
    type: string,
    field: string,
    id?: number,
  ) {
    return await $authHost.post(api + `file/` + type + "/" + id, data, {
      body: { field },
    });
  }

  /**
   * Отчет 25
   * @param id - айди
   * @param days - количество дней, по которым вести учет
   */
  static async getAnswer25Info(id: number, days: number) {
    return await $authHost.get(api + "answer25", { params: { id, days } });
  }
}
