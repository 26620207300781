import { ReactNode } from "react";
import styles from "./list.module.scss";

interface IListProps {
  data: ReactNode[];
  pointerCircle?: boolean;
}

function List(props: IListProps) {
  const { data } = props;
  let x = 1;

  return (
    <div>
      <ol className={props.pointerCircle ? styles.ListSimple : styles.List}>
        {data.map((line) => (
          <li key={(x++).toString()}>
            <div className={styles.ListText}>{line}</div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default List;
