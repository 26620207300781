import * as React from "react";
import { Link } from "react-router-dom";
import List from "../../../shared/ui/list/list";
import styles from "./analytics.module.scss";

export function Analytics() {
  return (
    <div>
      <h2 className={styles.Title}>АНАЛИТИКА</h2>

      <List
        pointerCircle={true}
        data={[
          <Link
            to={
              "/articles/sekrety-fml-239-ili-pochemu-litsej-pobezhdaet-moskvichej-na-olimpiadakh"
            }
          >
            2021 - Секреты ФМЛ 239 или почему лицей побеждает москвичей на
            олимпиадах
          </Link>,

          <Link
            to={"/articles/fml-239-lider-vsosch-2022"}
          >
            2022 - Заключительный этап Всероссийской олимпиады по математике –
            ФМЛ 239 снова на коне!
          </Link>,
        ]}
      />
    </div>
  );
}
