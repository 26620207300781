import React from "react";
import styles from "./dropDown.module.scss";
import { Menu, Dropdown as MUIDropdown, MenuButton, MenuItem } from "@mui/base";

export interface IDropDownProps {
  text: string;
  items: any[];
}

function DropDown(props: IDropDownProps) {
  const { text, items } = props;
  return (
    <MUIDropdown>
      <MenuButton className={styles.header}>{text}</MenuButton>

      <Menu className={styles.menu}>
        {items.map((item) => (
          <MenuItem key={item.key}>{item.label}</MenuItem>
        ))}
      </Menu>
    </MUIDropdown>
  );
}

export default DropDown;
