import CourseService from "../../../APIService/CourseService";
import { CourseTypeEnum } from "../../../constants/enums";

export const fetchThemes = async () => {
  const response = await CourseService.getThemesList({
    courseType: CourseTypeEnum.THEME,
    take: 10,
    page: 0,
  });

  return response;
};
