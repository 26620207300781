import { AnswerTypeEnum } from "../constants/enums";

export const getForbiddenSymbols = (tmpName: string) => {
  let forbiddenSymbols;
  switch (tmpName) {
    case AnswerTypeEnum.TYPE_21:
      forbiddenSymbols = null;
      break;
    case AnswerTypeEnum.TYPE_1:
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё)}({!@#$%^&*><?/]";
      break;
    case AnswerTypeEnum.TYPE_2:
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё]";
      break;
    case AnswerTypeEnum.NO_SPACES:
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё]";
      break;
    case AnswerTypeEnum.ALG:
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё]";
      break;
    case AnswerTypeEnum.ALG2:
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё]";
      break;
    case AnswerTypeEnum.REG_EXP + "%s":
      forbiddenSymbols = null;
      break;
    case AnswerTypeEnum.REG_EXP + "%d":
      forbiddenSymbols = "[a-zA-ZА-ЯЁа-яё №^;:<>?\"'!@#$%^&*()_{}]";
      break;
    default:
      forbiddenSymbols = null;
      break;
  }
  return forbiddenSymbols;
};
