import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../shared/ui/button";
import { LessonCost } from "../../lessonCost";
import styles from "./teacher.module.scss";

export function Teacher() {
  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>Уроки с преподавателем</h2>

        <LessonCost />

        <Link to={"/fefelova"}>
          <Button>
            <span> Далее</span>
          </Button>
        </Link>
      </div>

      <img
        width="295"
        height="261"
        className={styles.image}
        src={"/images/teacher1.svg"}
        alt=""
      />
    </div>
  );
}
