import { TCourseThemes } from "../../../types/CourseType";
import { TSchoolOutput, TSchool } from "../../../types/SchoolType";
import styles from "./container.module.scss";
import { HashLink } from "react-router-hash-link";

function CustomTable(props: { tableData: TSchoolOutput[] | TCourseThemes[] }) {
  // const theme = useTheme();
  const tableData = props.tableData.slice(0, 10);
  const isCourseThemes = !!tableData[0] && "taskCount" in tableData[0];
  if (!isCourseThemes) {
    // @ts-ignore
    tableData.map((x) => (x.items = x.items.slice(0, 10)));
  }

  return (
    <div>
      {isCourseThemes ? (
        <ol className={styles.Table}>
          {tableData.map((data: any) => (
            <li key={data.name} className={styles.Li}>
              <HashLink
                className={styles.ThemeLinks}
                to={`/exam#${data.shortName || data.name}`}
              >
                {data.shortName || data.name}
              </HashLink>
            </li>
          ))}
        </ol>
      ) : (
        tableData
          // @ts-ignore
          .filter((x) => x.group === "СПБ" || x.group === "МОСКВА")
          .sort(
            (
              a: TSchoolOutput | TCourseThemes,
              b: TSchoolOutput | TCourseThemes,
            ) => a.group?.localeCompare(b?.group),
          )
          .map((data: any) => (
            <div key={data.group}>
              <p className={styles.MainText}>
                {data.group === "СПБ"
                  ? "Санкт-Петербург:"
                  : data.group === "МОСКВА"
                    ? "Москва:"
                    : data.group}
              </p>
              <ol className={styles.Table}>
                {data.items
                  .filter(
                    (x: { shortName: string }) =>
                      x.shortName !== "Обучение работе с тренажером",
                  )
                  .map((data: TSchool) => (
                    <li key={data.id} className={styles.Li}>
                      <HashLink
                        className={styles.ThemeLinks}
                        to={`/exam#${data.shortName || data.name}`}
                      >
                        {data.shortName || data.name}
                      </HashLink>
                    </li>
                  ))}
              </ol>
            </div>
          ))
      )}
    </div>
  );
}

export default CustomTable;
